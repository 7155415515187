<i18n locale="th" lang="yaml" >
chart.name : "จำนวน Firmware ที่ใช้"
chart.summary : "มี Drone ทั้งหมด {total} @:common.drone.unit"
firmware.incorrect : "ลง Firmware ผิด"
firmware.undefined : "ไม่ระบุ Firmware"
</i18n>

<template>
	<ChartContainer
		:is-empty="total <= 0"
		:summary="summary"
		:custom-legend="true"
		:legends="legends"
		:container-width="300"
		v-bind="$attrs">
		<PieChart ref="chart" :chart-dataset="dataset" :options="options" :hide-legend="true"/>

	</ChartContainer>
</template>


<script>
import ChartContainer from "./general/ChartContainer.vue"
import PieChart from "./general/PieChart.vue"
import _randomColor from "randomcolor"

export default {
	components : {
		ChartContainer ,PieChart
	} ,
	props : {
		firmwareSet : {
			type : null,
			default : () => []
		} ,
		chartData : {
			type : null ,
			default : () => []
		}
	} ,
	data() {
		return {
			total : 0 ,
			legends : []
		}
	} ,
	computed : {
		summary() {
			if(this.total <=0)
				return undefined;
			else
				return this.$t('chart.summary',{total : this.total})
		},
		dataset() {
			return this.convertDataset()
		},
		options() {
			return {
				aspectRatio : 1.2
			}
		}
	} ,
	methods : {
		convertDataset() {
			this.total = 0
			if (!this.$notEmpty(this.chartData) || !('firmwares' in this.chartData))
				return null

			const chartData = this.chartData
			const numDrones = [];
			const labels = [];
			const colors = [];

			if ('incorrect' in chartData && chartData.incorrect > 0) {
				labels.push(this.$t('firmware.incorrect'))
				colors.push('#D22027')
				numDrones.push(chartData.incorrect)
				this.total += chartData.incorrect
				this.legends.push({
					label : this.$t('firmware.incorrect') + "("+chartData.incorrect+")" ,
					color : '#D22027'
				})
			}
			if ('undefined' in chartData && chartData.undefined > 0) {
				labels.push(this.$t('firmware.undefined'))
				colors.push('#BDBDBD')
				numDrones.push(chartData.undefined)
				this.total += chartData.undefined
				this.legends.push({
					label : this.$t('firmware.undefined') + "("+chartData.undefined+")",
					color : '#BDBDBD'
				})
			}
			const firmwareColors = _randomColor({luminosity: 'light',count : Object.keys(chartData.firmwares).length})
			var colorIdx = 0
			for (const id in chartData.firmwares) {
				const count  = chartData.firmwares[id]
				const firmware = this.firmwareSet.firmwares.find((obj) => obj.id==id)
				if (!firmware) {
					return
				}
				const color = firmwareColors[colorIdx++]
				labels.push(firmware.name)
				numDrones.push(count)
				colors.push(color)
				this.total += count
				this.legends.push({
					label : firmware.name  + "("+count+")",
					color : color
				})
			}
			return {
				datasets : [{
					data : numDrones,
					backgroundColor : colors
				}] ,
				labels
			}
		} ,
	}
}
</script>
