<i18n locale="th" lang="yaml" src="@i18n/drone/firmware.th.yaml"></i18n>
<i18n locale="th" lang="yaml" >
firmware_modal.title : "Firmware"
firmware_modal.title.update : "แก้ไขข้อมูล Firmware"
firmware_modal.ok : "เพิ่ม"
firmware_modal.ok.update : "@:common.save"

firmware.field.name.placeholder : "เลข Version ของ Firmware นี้"
firmware.field.remark.placeholder : "หมายเหตุ เช่น แก้ไขปัญหา ..."
firmware.field.released_date.help : "วันที่เริ่มใช้งาน Firmware ตัวนี้"
</i18n>

<template>
	<a-modal
		:visible="visible"
		:title="cModalTitle"
		:ok-text="cModalOkLabel"
		:width="350"
		:confirm-loading="loading"
		:cancel-button-props="{ props: { disabled: loading } }"
		:mask-closable="!loading"
		@ok="handleOk"
		@cancel="handleCancel">
		<a-form  :form="formObj">
			<a-form-item :label="$t('firmware.field.name')">
				<a-input
					v-decorator="[
						'name' ,
						{ rules: [
							{ required: true ,whitespace: true ,message: $tt('validate.required','firmware.field.name') },
						] },
					]"
					type="text"
					:placeholder="$t('firmware.field.name.placeholder')">
				</a-input>
			</a-form-item>
			<a-form-item :label="$t('firmware.field.released_date')"
				:extra="$t('firmware.field.released_date.help')">
				<MyDatePicker
					v-decorator="['released_date',
							{
								rules: [
									{ required: true ,whitespace: true ,message: $tt('validate.required','firmware.field.released_date') },
								] ,
							}]"
					format="DD MMMM YYYY"/>
			</a-form-item>
			<a-form-item :label="$t('firmware.field.remark')">
				<a-textarea
					v-decorator="['remark']"
					:auto-size="{ minRows: 3,maxRows: 5 }"
					:placeholder="$t('firmware.field.remark.placeholder')" />
			</a-form-item>
		</a-form>
	</a-modal>
</template>

<script>
import HasAntdFormMixin from '@mixins/HasAntdFormMixin.vue'
import { updateFieldsValue } from '@utils/formUtil'
import MyDatePicker from "@components/input/MyDatePicker.vue"
export default {
	components : {
		MyDatePicker
	} ,
	mixins : [HasAntdFormMixin] ,
	props : {
		loading : {
			type : Boolean,
			default : false
		}
	} ,
	data() {
		return {
			visible : false,
			mode : '',
			firmware : {} ,
			firmwareSet : {} ,
		}
	} ,
	computed : {
		isUpdate() {
			return this.mode == 'update'
		} ,
		cModalTitle() {
			return this.isUpdate ? this.$t('firmware_modal.title.update') :  this.$t('firmware_modal.title')
		} ,
		cModalOkLabel() {
			return this.isUpdate ? this.$t('firmware_modal.ok.update') : this.$t('firmware_modal.ok')
		} ,
	} ,
	methods : {
		openModal(firmwareSet,firmware) {
			if (firmware.id) {
				this.mode = 'update'
				this.firmware = {...firmware}
			} else {
				this.mode = 'add'
				this.firmware = {}
			}
			this.firmwareSet = {...firmwareSet}
			this.visible = true
			this.$nextTick(() => {
				this.formObj.resetFields()
				updateFieldsValue(this.formObj,this.firmware)
			})
		} ,
		closeModal() {
			this.mode = ''
			this.visible = false
			this.firmware = {}
			this.firmwareSet = {}
		} ,
		formErrors(error) {
			this.formSubmitErrors(error)
		} ,
		handleOk() {
			this.formObj.validateFields((err,values) => {
				if (!err) {
					this.$emit('submit',{
						formData : {...values} ,
						mode : this.mode,
						id : this.firmware.id ,
						firmware_set_id : this.firmwareSet.id
					})
				} else {
					this.displayValidateErrorMessage()
				}
			})
		} ,
		handleCancel() {
			if (this.loading)
				return;
			this.closeModal()
		} ,
	}
}
</script>
