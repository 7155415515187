<i18n locale="th" lang="yaml" >
page.title : "Firmware"
page.description : "รายการ Firmware ที่มีในระบบปัจจุบัน"

firmware_set.add.button : "เพิ่ม Firmware Set ชุดใหม่"

firmware_set.confirm.title.add : "ยืนยันการเพิ่ม Firmware Set ใหม่"
firmware_set.confirm.message.add : "คุณต้องการเพิ่ม Firmware Set ใหม่นี้ ?"
firmware_set.success.add : "ทำการเพิ่ม Firmware Set {name} เรียบร้อย"

firmware_set.confirm.title.update : "ยืนยันการแก้ไข Firmware Set นี้"
firmware_set.confirm.message.update : "คุณต้องการแก้ไข Firmware Set นี้ ?"
firmware_set.success.update : "ทำการแก้ไข Firmware Set {name} เรียบร้อย"

firmware_set.confirm.title.delete : "ทำการลบ Firmware Set {name} นี้"
firmware_set.confirm.message.delete : "คุณต้องการลบ Firmware Set {name} นี้ ? (ไม่สามารถนำข้อมูลกลับมาได้)"
firmware_set.success.delete : "ทำการลบ Firmware Set {name} เรียบร้อย"

firmware.confirm.title.add : "ยืนยันการเพิ่ม Firmware Version ใหม่"
firmware.confirm.message.add : "คุณต้องการเพิ่ม Firmware Version ใหม่นี้ ?"
firmware.success.add : "ทำการเพิ่ม Firmware Version {name} เรียบร้อย"

firmware.confirm.title.update : "ยืนยันการแก้ไข Firmware Version นี้"
firmware.confirm.message.update : "คุณต้องการแก้ไข Firmware Version นี้ ?"
firmware.success.update : "ทำการแก้ไข Firmware Version {name} เรียบร้อย"

firmware.confirm.title.delete : "ทำการลบ Firmware Version {name} นี้"
firmware.confirm.message.delete : "คุณต้องการลบ Firmware Version {name} นี้ ? (ไม่สามารถนำข้อมูลกลับมาได้)"
firmware.success.delete : "ทำการลบ Firmware Version {name} เรียบร้อย"
</i18n>

<template>
	<div class="page page-padding page-fit-mobile">
		<my-page-header
			:title="$t('page.title')"
			:description="$t('page.description')"/>
		<div v-if="canManageFirmware" class="page-action-right">
			<a-button
				class="btn-page-action"
				type="primary"
				icon="plus"
				@click="handleFirmwareSetCreate">
				{{$t('firmware_set.add.button')}}
			</a-button>
		</div>
		<MyCardList
			:loading="loading"
			:empty="firmwareSets.length === 0"
			class="firmware-list">
			<MyLoadingSpin slot="loading" size="small" />

			<FirmwareSetCard v-for="firmwareSet of firmwareSets"
				:key="firmwareSet.id"
				:can-manage="canManageFirmware"
				:firmware-set="firmwareSet"
				:stat="firmwareStats"
				@firmware_set="handleFirmwareSet"
				@firmware="handleFirmware" />

		</MyCardList>
		<FirmwareSetModal
			ref="setModalRef"
			@submit="handleSetModalSubmit"/>
		<FirmwareModal
			ref="firmwareModalRef"
			@submit="handleFirmwareModalSubmit"/>
	</div>
</template>

<script>
import MyLoadingSpin from "@components/common/MyLoadingSpin.vue"
import MyCardList from "@components/common/MyCardList.vue"
import FirmwareSetCard from "@components/firmware/FirmwareSetCard.vue"
import FirmwareSetModal from "@components/firmware/FirmwareSetModal.vue"
import FirmwareModal from "@components/firmware/FirmwareModal.vue"
import axios from "axios"
import PageMixin from "@mixins/PageMixin.vue"
import ApiError from '@utils/errors/ApiError'
import {mapActions} from "vuex"
export default {
	components : {
		MyCardList  ,MyLoadingSpin ,
		FirmwareSetCard ,
		FirmwareSetModal , FirmwareModal,
	} ,
	page() {
		return {
			title: this.$t('page.title'),
		}
	} ,
	mixins : [PageMixin],
	data() {
		return {
			firmwareSets : [] ,
			firmwareStats : [],
			loading : false,
		}
	} ,
	computed : {
		canManageFirmware() {
			return this.$authorize('update','firmware')
		} ,

	} ,
	mounted() {
		this.fetchData();
	} ,
	methods : {
		...mapActions('drone' ,['fetchFirmwareSets']) ,
		fetchData() {
			this.showPageLoading(true)
			axios.get("/api/firmwares/search",{params: {mode : "full"}}).then((response) => {
				this.firmwareSets = response.data.data.firmware_sets
				this.firmwareStats = response.data.data.stat
			}).catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error));
			}).finally(() => {
				this.hidePageLoading()
			})
		} ,
		_sendRequest(axiosConfig,prefix,suffix,name,successFn=undefined,errorFn=undefined) {
			this.$confirm({
				title : this.$t(prefix+'.confirm.title.'+suffix,{name}) ,
				content : this.$t(prefix+'.confirm.message.'+suffix,{name}) ,
				maskClosable : true,
				onOk: () => {
					this.showPageLoading(true)
					axios(axiosConfig).then((response) => {
						if (successFn) {
							successFn()
						}
						this.$message.success(this.$t(prefix+'.success.'+suffix,{name}))
						this.fetchData()
						this.fetchFirmwareSets()
					}).catch((error)=>{
						if (errorFn) {
							errorFn(error)
						} else {
							this.$message.error(ApiError.getDisplayErrorMessage(this,error))
						}
						this.hidePageLoading()
					})
				}
			})
		} ,
		handleFirmwareSet(payload) {
			const firmwareSet = payload.firmware_set
			if (payload.action == 'update') {
				this.$refs.setModalRef.openModal(firmwareSet)
			} else if (payload.action == 'delete') {
				const axiosConfig = {
					method : 'delete',
					url : '/api/firmwares/'+firmwareSet.id
				}
				this._sendRequest(axiosConfig,'firmware_set','delete',firmwareSet.name)
			} else if (payload.action == 'create_firmware') {
				this.$refs.firmwareModalRef.openModal(firmwareSet,{})
			}
		} ,
		handleFirmware(payload) {
			const firmwareSet = payload.firmware_set
			const firmware = payload.firmware
			if (payload.action == 'update') {
				this.$refs.firmwareModalRef.openModal(firmwareSet,payload.firmware)
			} else if (payload.action == 'delete') {
				const axiosConfig = {
					method : 'delete',
					url : '/api/firmwares/delete-firmware/'+firmware.id
				}
				this._sendRequest(axiosConfig,'firmware','delete',firmware.name)
			}
		} ,
		handleFirmwareSetCreate() {
			this.$refs.setModalRef.openModal({})
		} ,
		handleSetModalSubmit(payload) {
			let suffix = "add"
			const axiosConfig = {
				method : 'post' ,
				url : "/api/firmwares/create" ,
				data : payload.formData
			};
			if (payload.mode == 'update') {
				suffix = "update"
				axiosConfig.method = 'put'
				axiosConfig.url = "/api/firmwares/"+payload.id
			}
			this._sendRequest(axiosConfig,'firmware_set',suffix,payload.name,()=>{
				this.$refs.setModalRef.closeModal()
			},(error) => {
				this.$refs.setModalRef.formErrors(error)
			})
		} ,
		handleFirmwareModalSubmit(payload) {
			let suffix = "add"
			const axiosConfig = {
				method : 'post' ,
				url : "/api/firmwares/create-firmware/"+payload.firmware_set_id ,
				data : payload.formData
			};
			if (payload.mode == 'update') {
				suffix = "update"
				axiosConfig.method = 'put'
				axiosConfig.url = "/api/firmwares/update-firmware/"+payload.id
			}
			this._sendRequest(axiosConfig,'firmware',suffix,payload.formData.name,()=>{
				this.$refs.firmwareModalRef.closeModal()
			},(error) => {
				this.$refs.firmwareModalRef.formErrors(error)
			})
		}

	}
}
</script>

<style lang="less" scoped>
.firmware-list {
	width : 100%;
	min-height : 200px;
	flex-direction: column;
}
</style>
